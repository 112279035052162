import type { ProjectConfig } from '@/types/project-config/ProjectConfig'

const projectConfig: ProjectConfig = {
  emailSuffixs: [
    '@gmail.com',
    '@hotmail.com',
    '@yahoo.com',
    '@outlook.com',
    '@live.com',
    '@icloud.com',
    '@etb.net.co',
    '@une.net.co',
    '@misena.edu.co',
    '@yahoo.com.co',
  ],
}

export default projectConfig
